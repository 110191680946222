/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Gist, Image} from "smooth-doc/components";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    a: "a",
    h1: "h1",
    div: "div",
    h2: "h2",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "This article was first published @medium: ", React.createElement(_components.a, {
    href: "https://medium.com/@ivangsa/from-openapi-to-karatedsl-tests-with-zenwave-sdk-d7f885d15f99"
  }, "From OpenAPI to KarateDSL Tests with ZenWave SDK"), "."), "\n"), "\n", React.createElement(Image, {
    src: "OpenAPI2Karate.excalidraw.svg",
    alt: "From OpenAPI to KarateDSL Tests with ZenWave SDK"
  }), "\n", React.createElement(_components.h1, {
    id: "from-openapi-to-karatedsl-tests-with-zenwave-sdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#from-openapi-to-karatedsl-tests-with-zenwave-sdk",
    "aria-label": "from openapi to karatedsl tests with zenwave sdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "From OpenAPI to KarateDSL Tests with ZenWave SDK"), "\n", React.createElement(_components.p, null, "How to save time and typing by generating KarateDSL tests from OpenAPI definitions..."), "\n", React.createElement(_components.p, null, "Writing tests manually can be time-consuming, especially when you’re dealing with large API payloads and multiple endpoints."), "\n", React.createElement(_components.p, null, "What if you could streamline this process and generate tests from your existing OpenAPI definitions? That’s where ZenWave SDK comes into play, helping you automate KarateDSL tests based on your OpenAPI spec."), "\n", React.createElement(_components.p, null, "In this article, we’ll explore how ZenWave SDK can help you generate comprehensive ", React.createElement(_components.a, {
    href: "https://karatelabs.github.io/karate/"
  }, "KarateDSL"), " tests, including business flow tests that connects multiple endpoints on a single test/process, saving you both time and effort."), "\n", React.createElement(_components.h2, {
    id: "karatedsl",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#karatedsl",
    "aria-label": "karatedsl permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "KarateDSL"), "\n", React.createElement(_components.p, null, "KarateDSL is a popular framework designed for API testing and more, known for its simplicity. It allows testers to write API tests in a scripting language that includes built-in support for making HTTP calls, verifying responses, and handling complex JSON and XML assertions with minimal coding."), "\n", React.createElement(_components.p, null, "In KarateDSL JSON is kind-of native, and it's quite easy to write complex payloads and assertions in a compact way, without much boilerplate code."), "\n", React.createElement(_components.p, null, "Still it takes time and effort to bootstrap a new test from a blank page, especially when you have a large API with many endpoints and complex payloads."), "\n", React.createElement(_components.p, null, "Thanks to ZenWave SDK you can leverage the information about your API you already have in your OpenAPI definition files to generate KarateDSL tests, saving you time and typing."), "\n", React.createElement(_components.h2, {
    id: "installing-zenwave-sdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#installing-zenwave-sdk",
    "aria-label": "installing zenwave sdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Installing ZenWave SDK"), "\n", React.createElement(_components.p, null, "ZenWave SDK is composed of a set of tools to help you convert models and APIs (including OpenAPI and AsyncAPI) into different software artifacts. It features a CLI tool and an Model Editor for IntelliJ."), "\n", React.createElement(_components.p, null, "You can install an evergreen self updating ZenWave SDK CLI using JBang:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang alias add --force --fresh --name=zw release@zenwave360/zenwave-sdk\n")), "\n", React.createElement(_components.p, null, "Following these instructions for complete details about JBang and IntelliJ Editor: ", React.createElement(_components.a, {
    href: "https://www.zenwave360.io/docs/getting-started/"
  }, "https://www.zenwave360.io/docs/getting-started/")), "\n", React.createElement(_components.p, null, "Now you can use ", React.createElement(_components.code, null, "jbang zw"), " to generate a complete OpenAPI definition file from your ZDL model."), "\n", React.createElement(_components.h2, {
    id: "customers-openapi",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customers-openapi",
    "aria-label": "customers openapi permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customers OpenAPI"), "\n", React.createElement(_components.p, null, "We'll use the following OpenAPI definition file as an example. It contains a simple API for managing customers, including endpoints for creating, reading, updating, and deleting customers."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/EDALearn/EDA-Playground-Online-Food-Delivery/blob/main/modules/customers/src/main/resources/apis/openapi.yml"
  }, "https://github.com/EDALearn/EDA-Playground-Online-Food-Delivery/blob/main/modules/customers/src/main/resources/apis/openapi.yml")), "\n", React.createElement(Image, {
    alt: "Customers OpenAPI",
    src: "CustomerOpenAPI-UI.png"
  }), "\n", React.createElement(_components.p, null, "With this API and ZenWave SDK we can generate KarateDSL tests that cover all the endpoints and operations defined in the OpenAPI definition file, as well as business flow tests that span multiple API calls."), "\n", React.createElement(_components.h2, {
    id: "openapi-to-karatedsl-generator-plugin",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#openapi-to-karatedsl-generator-plugin",
    "aria-label": "openapi to karatedsl generator plugin permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "OpenAPI to KarateDSL Generator Plugin"), "\n", React.createElement(_components.p, null, "ZenWave SDK is composed of several plugins. One of them is the ", React.createElement(_components.a, {
    href: "https://www.zenwave360.io/zenwave-sdk/plugins/openapi-karate/"
  }, "OpenAPIKaratePlugin"), ", which generates KarateDSL tests from OpenAPI definitions."), "\n", React.createElement(_components.p, null, "Use the following command to generate a KarateDSL test for each operation defined in the OpenAPI definition file, grouping them by service:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang zw -p io.zenwave360.sdk.plugins.OpenAPIKaratePlugin \\\r\n    apiFile=src/main/resources/public/apis/openapi.yml \\\r\n    targetFolder=src/test/resources \\\r\n    testsPackage=io.zenwave360.example.adapters.web.tests \\\r\n    groupBy=service\n")), "\n", React.createElement(_components.p, null, "This is how the generated KarateDSL test looks like:"), "\n", React.createElement(Gist, {
    gist: "ivangsa/f115feff649567802c62c4e3def9bf0e",
    file: "CustomerApi.feature"
  }), "\n", React.createElement(_components.h2, {
    id: "generating-business-flow-tests-that-spans-multiple-api-calls",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#generating-business-flow-tests-that-spans-multiple-api-calls",
    "aria-label": "generating business flow tests that spans multiple api calls permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Generating Business Flow Tests that spans multiple API calls"), "\n", React.createElement(_components.p, null, "Now we are going to use ZenWave SDK to generate a KarateDSL script skeleton that tests the complete CRUD operations for a customer, as an easy to understand example of a business flow test."), "\n", React.createElement(_components.p, null, "Notice how we are using the ", React.createElement(_components.code, null, "groupBy=businessFlow"), " and ", React.createElement(_components.code, null, "operationIds"), " parameters to generate a KarateDSL test that covers the complete CRUD operations for a customer."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang zw -p io.zenwave360.sdk.plugins.OpenAPIKaratePlugin \\\r\n    apiFile=src/main/resources/model/openapi.yml \\\r\n    targetFolder=src/test/resources \\\r\n    testsPackage=io.zenwave360.example.adapters.web.tests \\\r\n    groupBy=businessFlow \\\r\n    businessFlowTestName=CustomerCRUDTest \\\r\n    operationIds=createCustomer,getCustomer,updateCustomer,deleteCustomer\n")), "\n", React.createElement(_components.p, null, "This is how the generated KarateDSL test looks like. Now it's your time as a tester/developer to fill in the blanks and connect the data from one operation to the next one."), "\n", React.createElement(Gist, {
    gist: "ivangsa/f115feff649567802c62c4e3def9bf0e",
    file: "CreateUpdateDeleteCustomerKarateTest.feature"
  }), "\n", React.createElement(_components.h2, {
    id: "run-and-debug-karatedsl-on-vscode-open-source",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#run-and-debug-karatedsl-on-vscode-open-source",
    "aria-label": "run and debug karatedsl on vscode open source permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Run and Debug KarateDSL on VSCode (Open Source)"), "\n", React.createElement(_components.p, null, "Now that you have your KarateDSL tests generated, you can run them using the KarateDSL CLI or the KarateIDE plugin for Visual Studio Code. ", React.createElement(_components.a, {
    href: "https://marketplace.visualstudio.com/items?itemName=KarateIDE.karate-ide"
  }, "ZenWave Karate IDE"), " is, besides KarateLabs PRO subscription, the only free and open-source alternative to Debug KarateDSL scripts in Visual Studio Code."), "\n", React.createElement(Image, {
    alt: "KarateIDE",
    src: "KarateIDE-MarketPlace.png",
    href: "https://marketplace.visualstudio.com/items?itemName=KarateIDE.karate-ide"
  }), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "https://medium.com/@ivangsa/debugging-karatedsl-scripts-in-vscode-open-source-d7b8938f9eb4"
  }, "https://medium.com/@ivangsa/debugging-karatedsl-scripts-in-vscode-open-source-d7b8938f9eb4"), " for more details about debuging KarateDSL scripts in Visual Studio Code."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
