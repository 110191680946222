/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*<FeatureImage style={{ display: 'inline', ...props.style }} src={springImageURL} />*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Link} from 'gatsby';
import {ScreenContainer, Button, HeroSection, Hero, HeroBody, HeroTitle, HeroTeaser, HeroActionList, HeroAction, FeatureSection, FeatureList, Feature, FeatureImage, FeatureTitle, FeatureText, Image, WideFeatureSection, WideFeature, WideFeatureText, WideFeatureImage} from "smooth-doc/components";
import heroBackgroundImageURL from "../images/hero-background.png";
import markdownImageURL from '../images/markdown.png';
import zdlImageURL from '../static/logos/conceptual-model.svg';
import sdkImageURL from '../static/logos/zenwave-sdk-dark.png';
import springBootImageURL from '../static/logos/spring-boot.svg';
import springImageURL from '../static/logos/spring.svg';
import dddImageURL from '../static/logos/ddd.svg';
import edaImageURL from '../images/event-driven-architectures-logo.svg';
import osiImageURL from '../static/logos/osi.svg';
import openApiImageURL from '../static/logos/openapi-logo.svg';
import asyncApiImageURL from '../static/logos/asyncapi-logo.png';
import tddImageURL from '../static/logos/tdd.svg';
import dddLogosImage from "../images/home/spiral.svg";
import sharedUnderstandingImage from "../images/home/common-language.svg";
import gearsDarkImage from "../images/home/laptop-gears.svg";
import eventStormingZDL from '../pages/docs/DDD/images/EventStorming-ZDL-Mapping.png';
import boundedContextMapping from '../images/home/bounded-context-mapping.svg';
import pluginScreenshot from './plugin-screenshot.png';
import pluginRunScreenshot from './plugin-run-screenshot.png';
import {marginRight} from '@xstyled/styled-components';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(ScreenContainer, null, React.createElement(HeroSection, null, React.createElement(Hero, {
    backgroundImageURL: heroBackgroundImageURL
  }, React.createElement(HeroBody, null, React.createElement(HeroTitle, null, "Create Software", React.createElement("br"), " Easy To Understand!"), "\r\n", React.createElement(HeroTeaser, null, "DDD and API-First Modeling Tools for Modern Distributed Applications."), React.createElement(HeroActionList, null, React.createElement(HeroAction, null, React.createElement(Button, {
    variant: "neutral",
    as: Link,
    to: "/docs/zenwave360/",
    style: {
      marginRight: '6px'
    }
  }, React.createElement(_components.p, null, "Browse Docs")), React.createElement(Button, {
    as: Link,
    to: "/docs/getting-started/"
  }, React.createElement(_components.p, null, "Getting Started"))))))), React.createElement(FeatureSection, null, React.createElement(FeatureList, null, React.createElement(Feature, null, React.createElement(FeatureImage, {
    src: zdlImageURL
  }), React.createElement(FeatureTitle, null, "Domain Model Language"), React.createElement(FeatureText, null, React.createElement(_components.p, null, "Concise and complete DDD modeling language for Event-Driven Architectures."))), React.createElement(Feature, null, React.createElement(FeatureImage, {
    style: {
      display: 'inline',
      'margin-right': '5px',
      ...props.style
    },
    src: sdkImageURL
  }), React.createElement(FeatureImage, {
    style: {
      display: 'inline',
      'margin-right': '5px',
      ...props.style
    },
    src: springBootImageURL
  }), React.createElement(FeatureTitle, null, "ZenWave SDK"), React.createElement(FeatureText, null, React.createElement(_components.p, null, "ZenWave SDK helps you create SpringBoot/SpringCloud applications in no time."))), React.createElement(Feature, null, React.createElement(FeatureImage, {
    src: dddImageURL
  }), React.createElement(FeatureTitle, null, "Domain Driven Design"), React.createElement(FeatureText, null, React.createElement(_components.p, null, "Business, Developer and Computer friendly Ubiquitous Language for your Domain Models and Bounded Contexts."))), React.createElement(Feature, null, React.createElement(FeatureImage, {
    src: edaImageURL
  }), React.createElement(FeatureTitle, null, "Event-Driven Architectures"), React.createElement(FeatureText, null, React.createElement(_components.p, null, "Designed for Event-Driven Architectures, mapping Event-Storming discoveries into your models."))), React.createElement(Feature, null, React.createElement(FeatureImage, {
    style: {
      display: 'inline',
      'margin-right': '5px',
      ...props.style
    },
    src: osiImageURL
  }), React.createElement(FeatureImage, {
    style: {
      display: 'inline',
      'margin-right': '5px',
      ...props.style
    },
    src: openApiImageURL
  }), React.createElement(FeatureImage, {
    style: {
      display: 'inline',
      ...props.style
    },
    src: asyncApiImageURL
  }), React.createElement(FeatureTitle, null, "API-First"), React.createElement(FeatureText, null, React.createElement(_components.p, null, "Leveraging open standards like OpenAPI and AsyncAPI."))), React.createElement(Feature, null, React.createElement(FeatureImage, {
    src: tddImageURL
  }), React.createElement(FeatureTitle, null, "API-Testing"), React.createElement(FeatureText, null, React.createElement(_components.p, null, "Testing as first class citizen. Helps you create complete test suites from your Models and APIs."))))), React.createElement(WideFeatureSection, null, React.createElement(ScreenContainer, null, React.createElement(WideFeature, null, React.createElement(WideFeatureImage, {
    src: dddLogosImage,
    alt: "Domain Driven Design is about Business Understanding",
    className: "only-small"
  }), React.createElement(WideFeatureText, {
    className: "wide-feature-text"
  }, React.createElement("h3", null, "Maintainable Software"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "It is ", React.createElement("strong", null, "not a matter of code"), " but a matter of ", React.createElement("strong", null, "understanding the business domain")), "\n", React.createElement(_components.li, null, "Software well designed ", React.createElement("strong", null, "should be easy to understand by all")), "\n")), React.createElement(WideFeatureImage, {
    src: dddLogosImage,
    alt: "Domain Driven Design is about Business Understanding",
    className: "only-wide"
  })), React.createElement(WideFeature, null, React.createElement(WideFeatureImage, {
    src: sharedUnderstandingImage,
    alt: "Gap between Domain Experts - Technical Knwoledge"
  }), React.createElement(WideFeatureText, {
    className: "wide-feature-text"
  }, React.createElement("h3", null, "Common Language (DSL)"), React.createElement(_components.p, null, "A common language closes the gap between:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("strong", null, "Domain Experts"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("strong", null, "Technical Experts"), "\n"), "\n", React.createElement(_components.li, null, "Working ", React.createElement("strong", null, "Software & Tests")), "\n"))), React.createElement(WideFeature, null, React.createElement(WideFeatureImage, {
    src: gearsDarkImage,
    alt: "",
    width: "300px",
    className: "only-small"
  }), React.createElement(WideFeatureText, {
    className: "wide-feature-text"
  }, React.createElement("h3", null, "ZenWave SDK"), React.createElement(_components.p, null, "Accelerate your development ", React.createElement("strong", null, "Converting your Models"), " into ", React.createElement("strong", null, "Software Artifacts:")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "API Definitions: OpenAPI, AsyncAPI..."), "\n", React.createElement(_components.li, null, "SpringBoot/SpringCloud Backend..."), "\n", React.createElement(_components.li, null, "API Testing: KarateDSL, Spring WebTestClient..."), "\n")), React.createElement(WideFeatureImage, {
    src: gearsDarkImage,
    alt: "",
    width: "300px",
    className: "only-wide"
  })))), React.createElement(ScreenContainer, null, React.createElement("section", null, React.createElement("div", {
    className: "home-links"
  }, React.createElement("div", null, React.createElement("a", {
    href: "/docs/domain-modeling/business-domain-modeling/"
  }, "Domain Modeling")), "\r\n", React.createElement("div", null, React.createElement("a", {
    href: "/docs/event-driven-design/zenwave-domain-language/"
  }, "ZDL Modeling Language")), "\r\n", React.createElement("div", null, React.createElement("a", {
    href: "/docs/zenwave-sdk"
  }, "ZenWave SDK"))))), React.createElement(ScreenContainer, {
    className: "home-screenshots"
  }, React.createElement("h2", null, "A Domain Language Designed for Event-Driven Architectures"), React.createElement(Image, {
    src: eventStormingZDL,
    alt: "Event Storming"
  }), React.createElement("h2", null, "... And Bounded Context Mapping"), React.createElement(Image, {
    src: boundedContextMapping,
    alt: "Bounded Context Mapping with ZDL",
    width: "100%"
  }), React.createElement("h2", null, "Can be read as documentation"), React.createElement(Image, {
    src: pluginScreenshot
  }), React.createElement("h2", null, "And easily converted into working software & tests"), React.createElement(Image, {
    src: pluginRunScreenshot
  })), React.createElement(ScreenContainer, null, React.createElement("section", null, React.createElement("div", {
    className: "home-links"
  }, React.createElement("div", null, React.createElement("a", {
    href: "/docs/domain-modeling/business-domain-modeling/"
  }, "Domain Modeling")), "\r\n", React.createElement("div", null, React.createElement("a", {
    href: "/docs/event-driven-design/zenwave-domain-language/"
  }, "ZDL Modeling Language")), "\r\n", React.createElement("div", null, React.createElement("a", {
    href: "/docs/zenwave-sdk"
  }, "ZenWave SDK"))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
