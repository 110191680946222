/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Image} from "smooth-doc/components";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    code: "code",
    h3: "h3",
    ol: "ol",
    pre: "pre",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "implementing-a-transactional-outbox-with-asyncapi-springmodulith-and-zenwavesdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#implementing-a-transactional-outbox-with-asyncapi-springmodulith-and-zenwavesdk",
    "aria-label": "implementing a transactional outbox with asyncapi springmodulith and zenwavesdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Implementing a Transactional OutBox With AsyncAPI, SpringModulith and ZenWaveSDK"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "When processes span multiple services without shared transaction boundaries, ensuring atomicity and consistency in distributed systems is challenging."), "\n", React.createElement(_components.p, null, "Distributed transactions with Two-Phase Commits (2PC) are complex, introduce significant performance overhead, and may not even be feasible for certain services."), "\n", React.createElement(_components.p, null, "The Outbox Pattern solves this by using a Database Transaction to store events in a dedicated \"outbox\" table within the same transaction as a database update. These events are then published to external systems, such as an email service or message broker, ensuring eventual consistency without the need for distributed transactions."), "\n", React.createElement(_components.p, null, "In this post, we’ll explore how we can implement a Transactional Outbox Pattern to:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Persist data to a supported transactional database (e.g., SQL or MongoDB)."), "\n", React.createElement(_components.li, null, "Send events to an external message broker like Kafka or RabbitMQ using Spring Cloud Stream."), "\n", React.createElement(_components.li, null, "Leverage Spring Modulith Events transactional features."), "\n", React.createElement(_components.li, null, "Use ZenWaveSDK Code Generator for AsyncAPI so you don’t need to write a single line of boilerplate code for the transactional outbox and event publishing."), "\n"), "\n", React.createElement(Image, {
    dark: "TransactionalOutBoxWithAsyncAPIAndSpringModulith-dark.png",
    light: "TransactionalOutBoxWithAsyncAPIAndSpringModulith-light.png",
    alt: "Transactional OutBox With AsyncAPI SpringCloud Stream And Spring Modulith"
  }), "\n", React.createElement(_components.h2, {
    id: "playground-project",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#playground-project",
    "aria-label": "playground project permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Playground Project"), "\n", React.createElement(_components.p, null, "Because working software is worth more than a thousand words, we’ll use a fully functional playground project that you can explore and test yourself."), "\n", React.createElement(_components.p, null, "We'll use the following project as our playground: ", React.createElement(_components.a, {
    href: "https://github.com/EDALearn/EDA-TransactionalOutbox-Modulith-JPA"
  }, "EDA-TransactionalOutbox-Modulith-JPA"), "."), "\n", React.createElement(_components.p, null, "This project provides a simple API for managing customer details, including REST endpoints for CRUD operations. These operations publish event notifications to a Kafka topic, using Avro as the payload format."), "\n", React.createElement(_components.p, null, "Key components of the project include:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "OpenAPI definition file"), ": ", React.createElement(_components.a, {
    href: "https://github.com/ZenWave360/zenwave-playground/blob/main/zenwave-jpa-example/src/main/resources/public/apis/openapi.yml"
  }, "openapi.yml")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "AsyncAPI definition file"), ": ", React.createElement(_components.a, {
    href: "https://github.com/ZenWave360/zenwave-playground/blob/main/zenwave-jpa-example/src/main/resources/public/apis/asyncapi.yml"
  }, "asyncapi.yml")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Avro schemas for events"), ": ", React.createElement(_components.a, {
    href: "https://github.com/ZenWave360/zenwave-playground/tree/main/zenwave-jpa-example/src/main/resources/public/apis/avro"
  }, "Avro schemas")), "\n"), "\n", React.createElement(_components.h2, {
    id: "distributed-transactions-problem-and-the-outbox-pattern",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#distributed-transactions-problem-and-the-outbox-pattern",
    "aria-label": "distributed transactions problem and the outbox pattern permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Distributed Transactions Problem and The Outbox Pattern"), "\n", React.createElement(_components.p, null, "When managing a ", React.createElement(_components.code, null, "Customer"), " entity, we encounter a significant challenge: ensuring the atomicity of persisting customer details in the database and publishing a related event to an external message broker. Since the database and the message broker do not share a transaction boundary, this can lead to potential inconsistencies."), "\n", React.createElement(_components.h3, {
    id: "key-scenarios-highlighting-the-problem",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#key-scenarios-highlighting-the-problem",
    "aria-label": "key scenarios highlighting the problem permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Key Scenarios Highlighting the Problem:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Event Sent Before Database Transaction Commits"), ":\r\nIf the event is published before the database transaction is committed, a rollback of the transaction would leave the system in an inconsistent state, as the published event cannot be undone."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Event Sent After Database Transaction Commits"), ":\r\nIf the event is published after the database transaction is committed, there’s no guarantee the event will actually be sent. A service crash or network failure could prevent the event from being published, resulting in data inconsistency."), "\n"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "@Service\r\npublic class CustomerService {\r\n    // ...\r\n\r\n    @Transactional\r\n    public Customer createCustomer(Customer input) {\r\n        log.debug(\"Request to save Customer: {}\", input);\r\n        var customer = mapper.update(new Customer(), input);\r\n        customer = customerRepository.save(customer); // Persist to DB\r\n\r\n        var customerEvent = eventsMapper.asCustomerEvent(customer);\r\n        eventsProducer.onCustomerEvent(customerEvent); // Emit Event to external Broker\r\n        return customer;\r\n    }\r\n}\n")), "\n", React.createElement(_components.p, null, "With the transactional outbox pattern, instead of directly sending the event to the external system, the call to ", React.createElement(_components.code, null, "eventsProducer.onCustomerEvent(customerEvent)"), " stores the event in a dedicated outbox table as part of the same transaction as the database update. An external process then reads from the outbox table and publishes the event to the message broker."), "\n", React.createElement(_components.p, null, "However, implementing this external process, managing the outbox table, and ensuring that events are published once and in the correct order can be complex and error-prone."), "\n", React.createElement(_components.p, null, "Fortunately, tools like ", React.createElement(_components.a, {
    href: "https://docs.spring.io/spring-modulith/reference/events.html#publication-registry"
  }, "Spring Modulith’s Event Publication Registry"), " and the ", React.createElement(_components.a, {
    href: "https://www.zenwave360.io/zenwave-sdk/plugins/asyncapi-spring-cloud-streams3/"
  }, "ZenWaveSDK Code Generator for AsyncAPI"), " simplify this process, so you can focus only on implementing your business logic."), "\n", React.createElement(_components.h2, {
    id: "zenwavesdk-code-generation-for-asyncapi-and-spring-cloud-streams",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zenwavesdk-code-generation-for-asyncapi-and-spring-cloud-streams",
    "aria-label": "zenwavesdk code generation for asyncapi and spring cloud streams permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZenWaveSDK Code Generation for AsyncAPI and Spring Cloud Streams"), "\n", React.createElement(_components.p, null, "ZenWaveSDK generates all the boilerplate code needed to send and receive events using Spring Cloud Stream from an AsyncAPI definition file."), "\n", React.createElement("iframe", {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/d5xddNWYR5I?si=G9MQO9afhNIw6ym2",
    title: "AsyncAPI: Full Code Generation for Java with ZenWave SDK",
    frameborder: "0",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
    referrerpolicy: "strict-origin-when-cross-origin",
    allowfullscreen: true
  }), "\n", React.createElement(_components.p, null, "From your AsyncAPI definition file, ZenWaveSDK generates:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "DTO/Models"), " for your event payloads."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Typed Header Objects"), " to ensure consistency."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "\"Intention-revealing\" Java interfaces"), " with methods named explicitly after your operationIds."), "\n", React.createElement(_components.li, null, "A lightweight implementation using Spring Cloud Streams that can be ", React.createElement(_components.code, null, "@Autowire"), "d directly into your services."), "\n"), "\n", React.createElement(_components.p, null, "You can then configure Spring Cloud Stream to send and receive messages using any of the ", React.createElement(_components.a, {
    href: "https://docs.spring.io/spring-cloud-stream/reference/index.html"
  }, "supported binders"), ", such as Kafka or RabbitMQ. With ZenWaveSDK, you don’t need to write any boilerplate code, allowing you to focus entirely on your business logic."), "\n", React.createElement("details", null, React.createElement("summary", null, "ZenWave SDK Maven Plugin Configuration"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-xml"
  }, "  <plugin>\r\n    <groupId>io.github.zenwave360.zenwave-sdk</groupId>\r\n    <artifactId>zenwave-sdk-maven-plugin</artifactId>\r\n    <version>${zenwave.version}</version>\r\n    <configuration>\r\n      <inputSpec>${project.basedir}/src/main/resources/public/apis/asyncapi.yml</inputSpec>\r\n      <addCompileSourceRoot>true</addCompileSourceRoot>\r\n      <addTestCompileSourceRoot>true</addTestCompileSourceRoot>\r\n    </configuration>\r\n    <executions>\r\n      <!-- DTOs -->\r\n      <!-- we skip DTOs generation in this case b/c we are using Avro for that -->\r\n\r\n      <!-- Generate PROVIDER -->\r\n      <execution>\r\n        <id>generate-asyncapi</id>\r\n        <phase>generate-sources</phase>\r\n        <goals>\r\n          <goal>generate</goal>\r\n        </goals>\r\n        <configuration>\r\n          <generatorName>spring-cloud-streams3</generatorName>\r\n          <configOptions>\r\n            <role>provider</role>\r\n            <style>imperative</style>\r\n            <transactionalOutbox>modulith</transactionalOutbox><!-- using Spring Modulith implementation -->\r\n            <modelPackage>${asyncApiModelPackage}</modelPackage>\r\n            <producerApiPackage>${asyncApiProducerApiPackage}</producerApiPackage>\r\n            <consumerApiPackage>${asyncApiConsumerApiPackage}</consumerApiPackage>\r\n          </configOptions>\r\n        </configuration>\r\n      </execution>\r\n    </executions>\r\n    <dependencies>\r\n      // ...\r\n    </dependencies>\r\n  </plugin>\n"))), "\n", React.createElement("details", null, React.createElement("summary", null, "Avro Maven Plugin Configuration"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-xml"
  }, "<plugin>\r\n  <groupId>org.apache.avro</groupId>\r\n  <artifactId>avro-maven-plugin</artifactId>\r\n  <version>1.11.1</version>\r\n  <executions>\r\n    <execution>\r\n      <goals>\r\n        <goal>schema</goal>\r\n      </goals>\r\n      <phase>generate-sources</phase>\r\n    </execution>\r\n  </executions>\r\n  <configuration>\r\n    <sourceDirectory>${project.basedir}/src/main/resources/public/apis/avro</sourceDirectory>\r\n    <outputDirectory>${project.basedir}/target/generated-sources/avro</outputDirectory>\r\n    <imports>\r\n      <import>${project.basedir}/src/main/resources/public/apis/avro/PaymentMethodType.avsc</import>\r\n      <import>${project.basedir}/src/main/resources/public/apis/avro/PaymentMethod.avsc</import>\r\n      <import>${project.basedir}/src/main/resources/public/apis/avro/Address.avsc</import>\r\n    </imports>\r\n  </configuration>\r\n</plugin>\n"))), "\n", React.createElement(_components.p, null, "To achieve this, we configure the ", React.createElement(_components.code, null, "zenwave-sdk-maven-plugin"), " and, in this case, also the ", React.createElement(_components.code, null, "avro-maven-plugin"), " in the ", React.createElement(_components.code, null, "pom.xml"), " file. This setup ensures that the necessary code is generated in the ", React.createElement(_components.code, null, "target/generated-sources"), " folder as part of your build process."), "\n", React.createElement(_components.p, null, "Since this configuration runs automatically during the build, every time you update your AsyncAPI definition file, you can be sure that your code remains in sync with your API definition."), "\n", React.createElement(Image, {
    dark: "ZenWaveSDK-Target-Generated-Sources-With-Arrow.png",
    light: "ZenWaveSDK-Target-Generated-Sources-With-Arrow-light.png"
  }), "\n", React.createElement(_components.p, null, "And because we configured the ", React.createElement(_components.code, null, "transactionalOutbox"), " option to ", React.createElement(_components.code, null, "modulith"), ", ZenWaveSDK will generate the code to use Spring Modulith Events Publication Registry to manage the transactional outbox for you."), "\n", React.createElement(Image, {
    dark: "ZenWaveSDK-TransactionalOutBox-Modulith-With-Arrows.png",
    light: "ZenWaveSDK-TransactionalOutBox-Modulith-With-Arrows-light.png"
  }), "\n", React.createElement(_components.h2, {
    id: "spring-modulith-events-publication-registry",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#spring-modulith-events-publication-registry",
    "aria-label": "spring modulith events publication registry permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Spring Modulith Events Publication Registry"), "\n", React.createElement(_components.p, null, "Because Event Externalization is enabled, and ", React.createElement(_components.code, null, "Message<?>"), " objects are configured for externalization (see below), these events are also stored transactionally in the Spring Modulith Event Publication Registry."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-yaml"
  }, "spring:\r\n  modulith.events.externalization.enabled: true\r\n  modulith.events.jdbc.schema-initialization.enabled: true\r\n  modulith.events.republish-outstanding-events-on-restart: true\n")), "\n", React.createElement(Image, {
    dark: "Modulith-Event-Publication-Registry.png",
    light: "Modulith-Event-Publication-Registry-light.png"
  }), "\n", React.createElement(_components.h2, {
    id: "using-spring-cloud-streams-to-externalize-modulith-events",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-spring-cloud-streams-to-externalize-modulith-events",
    "aria-label": "using spring cloud streams to externalize modulith events permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using Spring Cloud Streams to Externalize Modulith Events"), "\n", React.createElement(_components.p, null, "Now that Spring Modulith is managing our ", React.createElement(_components.code, null, "Message<?>"), " events, we need to configure one of the many supported ", React.createElement(_components.a, {
    href: "https://docs.spring.io/spring-modulith/reference/events.html#externalization"
  }, "event externalizers"), "."), "\n", React.createElement(_components.p, null, "If we were publishing POJOs in JSON format, we could use ", React.createElement(_components.code, null, "spring-modulith-events-kafka"), " to externalize events to a Kafka topic. However, since we want to externalize ", React.createElement(_components.code, null, "Message<?>"), " objects with Avro payloads, we will use ", React.createElement(_components.code, null, "io.zenwave360.sdk:spring-modulith-events-scs"), ", which supports serializing and deserializing ", React.createElement(_components.code, null, "Message<?>"), " objects, with or without Avro payloads."), "\n", React.createElement(_components.p, null, "For more details, see ", React.createElement(_components.a, {
    href: "https://github.com/ZenWave360/spring-modulith-events-spring-cloud-stream"
  }, "ZenWave360 Spring Modulith Events for Spring Cloud Stream"), "."), "\n", React.createElement(_components.p, null, "To enable this, add the following dependencies to your ", React.createElement(_components.code, null, "pom.xml"), " file:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-xml"
  }, "<dependency><!-- Spring Cloud Stream Externalization for Message<?> -->\r\n    <groupId>io.zenwave360.sdk</groupId>\r\n    <artifactId>spring-modulith-events-scs</artifactId>\r\n    <version>${spring-modulith-events-scs.version}</version>\r\n</dependency>\r\n<dependency><!-- Needed for serializing Avro payloads to db storage as json -->\r\n    <groupId>com.fasterxml.jackson.dataformat</groupId>\r\n    <artifactId>jackson-dataformat-avro</artifactId>\r\n</dependency>\n")), "\n", React.createElement(_components.p, null, "And ", React.createElement(_components.code, null, "@EnableSpringCloudStreamEventExternalization"), " to our Spring Boot Configuration:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "import io.zenwave360.modulith.events.scs.config.EnableSpringCloudStreamEventExternalization;\r\nimport org.springframework.context.annotation.Configuration;\r\n\r\n@Configuration\r\n@EnableSpringCloudStreamEventExternalization\r\nclass ExternalizationConfiguration {\r\n}\n")), "\n", React.createElement(_components.p, null, "This will route programmatically route our ", React.createElement(_components.code, null, "Message<?>"), " objects to the correct Spring Cloud Stream binding, but you don't need to worry about any of this as it is handled automatically behind the scenes."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "// this is some of what you get when adding @EnableSpringCloudStreamEventExternalization\r\n@AutoConfiguration\r\n@AutoConfigureAfter(EventExternalizationAutoConfiguration.class)\r\n@ConditionalOnProperty(name = \"spring.modulith.events.externalization.enabled\", havingValue = \"true\",\r\n        matchIfMissing = true)\r\npublic class MessageExternalizationConfiguration {\r\n\r\n    @Bean\r\n    EventExternalizationConfiguration eventExternalizationConfiguration() {\r\n        return EventExternalizationConfiguration.externalizing()\r\n            .select(event -> annotatedAsExternalized().test(event)\r\n                    || event instanceof Message<?> && getTarget(event) != null)\r\n            .route(Message.class, event -> RoutingTarget.forTarget(getTarget(event)).withoutKey())\r\n            .build();\r\n    }\r\n\r\n    private String getTarget(Object event) {\r\n        if (event instanceof Message<?> message) {\r\n            return message.getHeaders()\r\n                .get(SpringCloudStreamEventExternalizer.SPRING_CLOUD_STREAM_SENDTO_DESTINATION_HEADER, String.class);\r\n        }\r\n        return null;\r\n    }\r\n}\n")), "\n", React.createElement(_components.h2, {
    id: "automagic-transactional-outbox-implementation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#automagic-transactional-outbox-implementation",
    "aria-label": "automagic transactional outbox implementation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Automagic Transactional OutBox Implementation"), "\n", React.createElement(_components.p, null, "Our original code is now ", React.createElement(_components.em, null, "automagically"), " implementing the Transactional OutBox pattern using Spring Modulith Events Publication Registry and Spring Cloud Stream, all made possible by the ZenWaveSDK AsyncAPI Code Generator. 🚀🚀🚀"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "@Service\r\npublic class CustomerService {\r\n    // ...\r\n\r\n    @Transactional\r\n    public Customer createCustomer(Customer input) {\r\n        log.debug(\"Request to save Customer: {}\", input);\r\n        var customer = mapper.update(new Customer(), input);\r\n        customer = customerRepository.save(customer); // Persist to DB\r\n\r\n        var customerEvent = eventsMapper.asCustomerEvent(customer);\r\n        eventsProducer.onCustomerEvent(customerEvent); // Emit Event to external Broker via Tx OutBox\r\n        return customer;\r\n    }\r\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
