import React from "react";
import GATSBY_COMPILED_MDX from "C:/Users/ivangsa/workspace/zenwave/ZenWave360.github.io/website/pages/posts/TransactionalOutBoxWithAsyncAPIAndSpringModulith.mdx";
import {graphql} from "gatsby";
export const postQuery = graphql`
  query PostPage($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        pageType
        title
      }
    }
  }
`;
function PostTemplate({children}) {
  return children;
}
PostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
