/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "generating-openapi-definition-files-from-jdl-with-zenwavesdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#generating-openapi-definition-files-from-jdl-with-zenwavesdk",
    "aria-label": "generating openapi definition files from jdl with zenwavesdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Generating OpenAPI definition files from JDL with ZenWaveSDK"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./JDL-2-OpenAPI.svg",
    alt: "JDL-2-OpenAPI"
  })), "\n", React.createElement(_components.p, null, "Writing YAML by hand is no fun, but you can simplify the process of writing OpenAPI definition files by using a Domain Specific Language (DSL)."), "\n", React.createElement(_components.p, null, "Thanks to ZenWave SDK, you can convert JDL models into OpenAPI definition files. This can save time and effort in the development process while ensuring that your APIs follow best practices and standards."), "\n", React.createElement(_components.h2, {
    id: "jdl-example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#jdl-example",
    "aria-label": "jdl example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "JDL Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer {\r\n    username String required minlength(3) maxlength(250)\r\n    password String required minlength(3) maxlength(250)\r\n    email String required minlength(3) maxlength(250)\r\n    firstName String required minlength(3) maxlength(250)\r\n    lastName String required minlength(3) maxlength(250)\r\n}\r\nentity Address {\r\n    street String\r\n    city String\r\n    country String\r\n    zipCode String\r\n}\r\n\r\nrelationship OneToOne {\r\n    Customer{address} to Address{customer}\r\n}\n")), "\n", React.createElement(_components.p, null, "It will add ", React.createElement(_components.code, null, "x-business-entity"), " and ", React.createElement(_components.code, null, "x-business-entity-paginated"), " to generated schemas, very useful if you are also using ZenWave to generate a complete backend from JDL"), "\n", React.createElement(_components.h2, {
    id: "generating-openapi-definition-files-from-jdl-with-zenwavesdk-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#generating-openapi-definition-files-from-jdl-with-zenwavesdk-1",
    "aria-label": "generating openapi definition files from jdl with zenwavesdk 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Generating OpenAPI definition files from JDL with ZenWaveSDK"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "https://www.zenwave360.io/zenwave-sdk/plugins/jdl-to-openapi/"
  }, "JDL To OpenAPI Generator"), " for a complete list of options and ", React.createElement(_components.a, {
    href: "https://github.com/zenwave360/zenwave-sdk"
  }, "GitHub repository"), " for install instructions."), "\n"), "\n", React.createElement(_components.p, null, "ZenWave SDK will generate CRUD operations for your entities, including paginated lists and search operations."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang zw -p io.zenwave360.sdk.plugins.ZDLToOpenAPIPlugin \\\r\n    zdlFile=entities-model.jdl \\\r\n    idType=integer \\\r\n    idTypeFormat=int64 \\\r\n    targetFolder=. \\\r\n    targetFile=openapi.yml\n")), "\n", React.createElement(_components.p, null, "By using JDL to define your domain model and ZenWave SDK to convert it into an OpenAPI definition file, you can simplify the process of designing and documenting your APIs. This can improve the overall quality and consistency of your APIs, while also reducing errors and inconsistencies."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
